import { Box, BoxProps } from '@chakra-ui/react';
import { Response } from 'datx-jsonapi';
import { useResource, useResourcesInfinite } from 'datx-jsonapi-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LectureCarouselFlipping } from '@/components/ui/features/hubs-landing/LandingHeader/components/LectureCarouselFlipping';
import { LectureCarouselFlippingMobile } from '@/components/ui/features/hubs-landing/LandingHeader/components/LectureCarouselFlippingMobile';
import { NoResults } from '@/components/ui/shared/messages/NoResults/NoResults';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { useStores } from '@/hooks/useStores';
import { getHubBySlug } from '@/queries/hubs';
import { fetchLectureAccessPermissionsSideload } from '@/queries/lecture-access-permissions';
import { queryLecturesByQueryInfinite } from '@/queries/thin-lectures';
import { ThinLecture } from '@/resources/ThinLecture';
import { extractSWRInfiniteData } from '@/utils/swr/extractSWRInfiniteData';

export const HubLectureCarousel: FC<BoxProps> = ({ ...rest }) => {
  const { t } = useTranslation();
  const { store } = useStores();

  const { slug } = useQueryParams<{ slug: string }>({
    slug: StringParam,
  });

  const { data: hub } = useResource(getHubBySlug(slug));
  const { data, error, size, setSize } = useResourcesInfinite(
    hub?.id
      ? queryLecturesByQueryInfinite({
          filters: {
            scope: 'published',
            library: 'true',
            featured_for_hub_id: hub?.id,
          },
          pageSize: 16,
        })
      : null,
    { sideload: fetchLectureAccessPermissionsSideload(store) }
  );

  const { meta, ...swrExtract } = useMemo(
    () => extractSWRInfiniteData<ThinLecture>(data as Array<Response<ThinLecture>>, error, size),
    [data, error, size]
  );

  const onLoadMore = useCallback(() => setSize(size + 1), [setSize, size]);

  return (
    <Box {...rest}>
      {meta?.totalCount === 0 && <NoResults title={t('common.noResults')} />}
      {meta?.totalCount !== 0 && <LectureCarouselFlipping onLoadMore={onLoadMore} {...swrExtract} />}
      {meta?.totalCount !== 0 && <LectureCarouselFlippingMobile onLoadMore={onLoadMore} {...swrExtract} />}
    </Box>
  );
};
