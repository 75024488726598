import { HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { InView } from 'react-intersection-observer';

import { FlippingLectureCard } from '@/components/ui/shared/lecture/FlippingLectureCard/FlippingLectureCard';
import { LectureCardSkeleton } from '@/components/ui/shared/lecture/LectureCard/LectureCardSkeleton/LectureCardSkeleton';
import { ThinLecture } from '@/resources/ThinLecture';

interface ISubjectLecturesMobileProps {
  items: Array<ThinLecture>;
  isLoadingMore: boolean;
  isReachingEnd: boolean;
  isEmpty: boolean;
  onLoadMore: () => void;
}

export const LectureCarouselFlippingMobile: FC<ISubjectLecturesMobileProps> = ({
  items,
  isLoadingMore,
  isReachingEnd,
  onLoadMore,
}) => {
  if (items.length === 0) {
    return (
      <HStack mt={4} pb={3} spacing={5} overflowX="hidden" alignItems="stretch" display={{ base: 'flex', md: 'none' }}>
        {Array.from({ length: 3 }, (_, i) => (
          <LectureCardSkeleton h="full" minW="288px" border="1px solid #CACCCC" key={i} />
        ))}
      </HStack>
    );
  }

  return (
    <HStack display={{ base: 'flex', md: 'none' }} mt={4} pb={3} spacing={5} overflowX="scroll" alignItems="stretch">
      {items.map((lecture) => (
        <FlippingLectureCard key={lecture.id} minW="335px" lecture={lecture} />
      ))}
      <InView
        onChange={(inView) => {
          if (inView && !isReachingEnd && !isLoadingMore) {
            onLoadMore?.();
          }
        }}
      >
        {isLoadingMore ? <LectureCardSkeleton minW="288px" h="full" border="1px solid #CACCCC" /> : null}
      </InView>
    </HStack>
  );
};
