import { omitBy, isNil } from 'lodash';

import { QueryResources, QueryResourcesInfinite } from '@/lib/datx-jsonapi-react';
import { ThinSubject } from '@/resources/ThinSubject';

interface IQueryThinSubjectsInfiniteProps {
  filters: Record<string, string>;
  sort?: string;
  pageSize: number;
}

export const queryThinSubjectsInfinite = ({
  filters,
  sort = 'position',
  pageSize = 4,
}: IQueryThinSubjectsInfiniteProps): QueryResourcesInfinite<ThinSubject> => {
  return (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.links?.next) {
      return null;
    }
    const pageNumber = pageIndex + 1;

    return [
      ThinSubject.type,
      {
        filter: {
          ...omitBy(filters, isNil),
        },
        sort,
        params: [
          {
            key: 'page[size]',
            value: pageSize.toString(),
          },
          {
            key: 'page[number]',
            value: pageNumber.toString(),
          },
        ],
      },
    ];
  };
};

export const queryThinSubjectsSorted = (pageSize = 60): QueryResources<ThinSubject> => {
  return [
    ThinSubject,
    {
      sort: 'name',
      params: [
        {
          key: 'page[size]',
          value: pageSize.toString(),
        },
        {
          key: 'page[number]',
          value: '1',
        },
      ],
    },
  ];
};
