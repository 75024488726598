import { FC, ReactNode } from 'react';
import { SWRConfig } from 'swr';

import { useStores } from '@/hooks/useStores';
import { hydrate } from '@/lib/datx-jsonapi-react';

export interface IHydrateProps {
  fallback: Record<string, any>;
  children: ReactNode;
}

export const Hydrate: FC<IHydrateProps> = ({ children, fallback }) => {
  const { store } = useStores();

  return <SWRConfig value={{ fallback: fallback && hydrate(store, fallback) }}>{children}</SWRConfig>;
};
