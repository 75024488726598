
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/hubs-subdomain/[slug]",
      function () {
        return require("private-next-pages/hubs-subdomain/[slug]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/hubs-subdomain/[slug]"])
      });
    }
  