import { Box, BoxProps } from '@chakra-ui/react';
import { Response } from 'datx-jsonapi';
import { useResourcesInfinite } from 'datx-jsonapi-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SubjectSectionHeader } from '@/components/ui/features/hubs-landing/FeaturedSubjects/components/SubjectSectionHeader';
import { LectureCarouselFlipping } from '@/components/ui/features/hubs-landing/LandingHeader/components/LectureCarouselFlipping';
import { LectureCarouselFlippingMobile } from '@/components/ui/features/hubs-landing/LandingHeader/components/LectureCarouselFlippingMobile';
import { NoResults } from '@/components/ui/shared/messages/NoResults/NoResults';
import { LectureSort } from '@/enums/LectureSort';
import { useStores } from '@/hooks/useStores';
import { fetchLectureAccessPermissionsSideload } from '@/queries/lecture-access-permissions';
import { queryLecturesByQueryInfinite } from '@/queries/thin-lectures';
import { ThinLecture } from '@/resources/ThinLecture';
import { ThinSubject } from '@/resources/ThinSubject';
import { extractSWRInfiniteData } from '@/utils/swr/extractSWRInfiniteData';

interface ISubjectSectionProps extends BoxProps {
  subject: ThinSubject;
}
export const SubjectLecturesCarousel: FC<ISubjectSectionProps> = ({ subject, ...rest }) => {
  const { store } = useStores();
  const { t } = useTranslation();
  const { data, error, size, setSize } = useResourcesInfinite(
    subject?.id
      ? queryLecturesByQueryInfinite({
          filters: {
            scope: 'published',
            library: 'true',
            subject_subtree_of_id: subject?.id,
          },
          sort: `-${LectureSort.HeldAt}`,
          pageSize: 16,
        })
      : null,
    { sideload: fetchLectureAccessPermissionsSideload(store) }
  );

  const { meta, ...swrExtract } = useMemo(
    () => extractSWRInfiniteData<ThinLecture>(data as Array<Response<ThinLecture>>, error, size),
    [data, error, size]
  );

  const onLoadMore = useCallback(() => setSize(size + 1), [setSize, size]);

  return (
    <Box {...rest}>
      <SubjectSectionHeader subject={subject} />
      {meta?.totalCount === 0 && <NoResults title={t('common.noResults')} />}
      {meta?.totalCount !== 0 && <LectureCarouselFlipping onLoadMore={onLoadMore} {...swrExtract} />}
      {meta?.totalCount !== 0 && <LectureCarouselFlippingMobile onLoadMore={onLoadMore} {...swrExtract} />}
    </Box>
  );
};
