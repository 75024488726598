import { QueryResource, QueryResourceRelationship } from 'datx-jsonapi-react';

import { Hub } from '@/resources/Hub';
import { HubsSubjectsSubtree } from '@/resources/HubsSubjectsSubtree';

export const getHubBySlug = (slug: string): QueryResource<Hub> => {
  if (!slug) return null;
  return [
    Hub,
    slug,
    {
      params: [
        {
          key: 'find_by',
          value: 'slug',
        },
      ],
    },
  ];
};

export const getHubsSubtree = (id: string): QueryResourceRelationship<HubsSubjectsSubtree> => {
  return id ? [Hub.type, id, 'subjects_subtree'] : null;
};
