import { Skeleton, useBreakpointValue } from '@chakra-ui/react';
import { FC } from 'react';

import { Carousel } from '@/components/ui/shared/carousel/Carousel';
import { FlippingLectureCard } from '@/components/ui/shared/lecture/FlippingLectureCard/FlippingLectureCard';
import { ThinLecture } from '@/resources/ThinLecture';

interface ISubjectLecturesDesktopProps {
  items: Array<ThinLecture>;
  isLoadingMore: boolean;
  onLoadMore: () => void;
  isReachingEnd: boolean;
}

export const LectureCarouselFlipping: FC<ISubjectLecturesDesktopProps> = ({
  items,
  isLoadingMore,
  onLoadMore,
  isReachingEnd,
}) => {
  const widthCard = useBreakpointValue({ base: 346, lg: 390 }, { ssr: true });

  return (
    <Carousel
      display={{ base: 'none', md: 'block' }}
      mb={2}
      data={items}
      render={(lecture) => <FlippingLectureCard key={lecture.id} lecture={lecture} />}
      widthCard={widthCard}
      itemLoader={<LectureFlippingCardSkeleton />}
      onLoadMore={onLoadMore}
      isLoadingMore={isLoadingMore}
      isReachingEnd={isReachingEnd}
    />
  );
};

export function LectureFlippingCardSkeleton() {
  return (
    <Skeleton
      backgroundColor="white"
      borderRadius="8"
      h={{ base: '294px', md: '320px' }}
      w={{ base: '346px', lg: '390px' }}
    />
  );
}
