import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';

import { FeaturedSubjects } from '@/components/ui/features/hubs-landing/FeaturedSubjects/FeaturedSubjects';
import { LandingHeader } from '@/components/ui/features/hubs-landing/LandingHeader/LandingHeader';
import { LandingContentHeading } from '@/components/ui/features/landing/LandingContentHeading/LandingContentHeading';
import { LandingPartnersList } from '@/components/ui/features/landing/LandingPartnersList/LandingPartnersList';
import { Layout } from '@/components/ui/shared/layouts/Layout/Layout';
import { accessFrom } from '@/constants/custom-request-headers';
import { AccessFrom } from '@/enums/AccessFrom';
import { getHubBySlug } from '@/queries/hubs';
import { fetchLectureAccessPermissionsSideload } from '@/queries/lecture-access-permissions';
import { queryLecturesByQueryInfinite } from '@/queries/thin-lectures';
import { queryThinSubjectsInfinite } from '@/queries/thin-subjects';
import { generateStore } from '@/utils/generateStore';
import { Hydrate } from '@/utils/swr/Hydrate';
import { StringParam, urlQueryStringValidate } from '@/utils/url-query-string';

const HubsHomepage: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({ fallback }) => {
  return (
    <Hydrate fallback={fallback}>
      <Layout accessFrom={AccessFrom.Hubs}>
        <LandingHeader />
        <LandingContentHeading />
        <LandingPartnersList />
        <FeaturedSubjects />
      </Layout>
    </Hydrate>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const store = generateStore({ context });

  const { slug } = urlQueryStringValidate<{ slug: string }>(context.query, {
    slug: StringParam,
  });

  const { data: hub } = await store.fetchResource(getHubBySlug(slug));

  await store.fetchResourcesInfinite(
    hub?.id
      ? queryThinSubjectsInfinite({
          filters: {
            featured_for_hub_id: hub?.id,
          },
          sort: 'hub_featured_position',
          pageSize: 4,
        })
      : null
  );

  await store.fetchResourcesInfinite(
    hub?.id
      ? queryLecturesByQueryInfinite({
          filters: {
            scope: 'published',
            library: 'true',
            featured_for_hub_id: hub?.id,
          },
          pageSize: 16,
        })
      : null,
    { sideload: fetchLectureAccessPermissionsSideload(store) }
  );

  return {
    props: {
      fallback: store.fallback,
      accessFromLocation: context.req.headers[accessFrom] || AccessFrom.Core,
    },
  };
};
export default HubsHomepage;
