import { Container } from '@chakra-ui/react';
import { Response } from 'datx-jsonapi';
import { useResource, useResourcesInfinite } from 'datx-jsonapi-react';
import { useMemo } from 'react';
import { InView } from 'react-intersection-observer';

import { SubjectLecturesCarousel } from '@/components/ui/features/hubs-landing/FeaturedSubjects/components/SubjectLecturesCarousel';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { getHubBySlug } from '@/queries/hubs';
import { queryThinSubjectsInfinite } from '@/queries/thin-subjects';
import { ThinSubject } from '@/resources/ThinSubject';
import { extractSWRInfiniteData } from '@/utils/swr/extractSWRInfiniteData';

export const FeaturedSubjects = () => {
  const { slug } = useQueryParams<{ slug: string }>({
    slug: StringParam,
  });
  const { data: hub } = useResource(getHubBySlug(slug));
  const { data, error, size, setSize } = useResourcesInfinite(
    hub?.id
      ? queryThinSubjectsInfinite({
          filters: {
            featured_for_hub_id: hub?.id,
          },
          sort: 'hub_featured_position',
          pageSize: 4,
        })
      : null
  );

  const { items, isLoadingMore, isReachingEnd } = useMemo(
    () => extractSWRInfiniteData<ThinSubject>(data as Array<Response<ThinSubject>>, error, size),
    [data, error, size]
  );

  return (
    <Container pt="83px" w="full">
      {items?.map((subject) => (
        <SubjectLecturesCarousel key={subject.id} subject={subject} mb={7} _last={{ mb: '156px' }} />
      ))}

      <InView
        as="span"
        onChange={(inView) => {
          if (inView && !error && !isReachingEnd && !isLoadingMore) {
            setSize(size + 1);
          }
        }}
      ></InView>
    </Container>
  );
};
