import { InputGroup, Input, InputRightElement, forwardRef, InputProps, Divider, Icon } from '@chakra-ui/react';
import { Fragment, ReactNode } from 'react';

import SearchIcon from '@/assets/icons/ic-search.svg';

interface ISearchInputProps extends InputProps {
  actionSlot?: ReactNode;
}

export const SearchInput = forwardRef<ISearchInputProps, 'input'>((props, ref) => {
  const { placeholder, value, defaultValue, onChange, actionSlot, ...rest } = props;

  return (
    <InputGroup {...rest}>
      <Input
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        borderRadius="full"
        placeholder={placeholder}
        pr={actionSlot ? 20 : 10}
      />
      <InputRightElement pl={0} pr={2} minW="max-content" display="flex">
        {actionSlot && (
          <Fragment>
            {actionSlot}
            <Divider orientation="vertical" h={6} mx={2} />
          </Fragment>
        )}
        <Icon as={SearchIcon} color="black" boxSize="24px" />
      </InputRightElement>
    </InputGroup>
  );
});
