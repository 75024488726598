import { Flex, FlexProps, Heading, Skeleton, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/context/Analytics';
import { ThinSubject } from '@/resources/ThinSubject';

interface ISubjectSectionHeaderProps extends FlexProps {
  subject: ThinSubject;
}

export const SubjectSectionHeader: FC<ISubjectSectionHeaderProps> = ({ subject }) => {
  const { t } = useTranslation();
  const analytics = useAnalytics();

  const viewAllURL = `/lectures?subject_subtree_of_id=${subject?.id}`;

  if (!subject) {
    return (
      <Flex h="full" direction="row" justifyContent="space-between">
        <Skeleton h="32px" flex="1" mr="10px" />
        <Skeleton h="32px" flex="1" />
      </Flex>
    );
  }

  function linkClicked() {
    analytics.track('library_landing_view_all', {
      subject_name: subject?.name,
      subject_id: subject?.id,
    });
  }

  return (
    <Flex mb="18px" px={1} alignItems="center" justifyContent="space-between">
      <Heading fontSize={{ base: '2xl', md: '3xl' }}>{subject.name}</Heading>
      <Link onClick={linkClicked} href={viewAllURL}>
        <Text _hover={{ textDecoration: 'underline' }} w="58px" ml={2} fontWeight="bold">
          {t('lecture.viewAll')}
        </Text>
      </Link>
    </Flex>
  );
};
