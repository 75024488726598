import { Box, BoxProps, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HubLectureCarousel } from '@/components/ui/features/hubs-landing/LandingHeader/components/HubLectureCarousel';

export const FeaturedLectures: FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Box w="full" {...props}>
      <Heading mb={{ base: '4', md: '6' }} fontSize="3xl">
        {t('hubs.landing.featuredLectures')}
      </Heading>
      <HubLectureCarousel />
    </Box>
  );
};
