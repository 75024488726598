import { Button, ButtonProps, Flex, Link } from '@chakra-ui/react';
import { useResource } from 'datx-jsonapi-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { FeaturedLectures } from '@/components/ui/features/hubs-landing/LandingHeader/components/FeaturedLectures';
import { CoreHeroBody, CoreHeroTitle, CoreHubsHero } from '@/components/ui/shared/heroes/CoreHero/CoreHero';
import { SearchInput } from '@/components/ui/shared/inputs/SearchInput/SearchInput';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { getHubBySlug } from '@/queries/hubs';

const HubsNavigationButton = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...rest }, ref) => {
  return (
    <Button
      as={Link}
      size="sm"
      color="white"
      fontSize="16px"
      borderWidth="2px"
      boxSizing="border-box"
      w={{ base: 'full', md: '158px', lg: '155px' }}
      h={{ base: '46px', md: '56px' }}
      py="4"
      colorScheme="dark"
      variant="outline"
      ref={ref}
      {...rest}
    >
      {children}
    </Button>
  );
});

export const LandingHeader: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [searchValue, setSearchValue] = useState('');

  const { slug } = useQueryParams<{ slug: string }>({
    slug: StringParam,
  });

  const { data } = useResource(getHubBySlug(slug));

  const [, cancel] = useDebounce(
    () => {
      if (searchValue?.length >= 3) {
        router.push({ pathname: `/lectures`, query: { query: encodeURIComponent(searchValue) } });
      }
    },
    300,
    [searchValue]
  );

  useEffect(() => {
    return () => cancel();
  }, [cancel]);

  const title = data?.headerTitle || t('hubs.landing.heading');
  const backgroundImage = data?.originalHeaderImageUrl || '/images/hubs/purple-liquid.png';

  return (
    <CoreHubsHero h={{ base: '864px', md: '785px', lg: '767px' }} backgroundImage={`url(${backgroundImage})`}>
      <CoreHeroBody h={{ base: '851px', sm: '741px', md: '718px' }} px={{ base: 5, md: 10, lg: 5 }}>
        <Flex
          py={{ base: 12, lg: 20 }}
          px={5}
          color="white"
          left="0"
          position="absolute"
          top="0"
          h="100%"
          flexDirection="column"
          alignItems="center"
          w="full"
        >
          <CoreHeroTitle p="0" textAlign="center" maxW="710" fontSize="3xl" lineHeight="36px">
            {title}
          </CoreHeroTitle>
          <SearchInput
            bg="white"
            color="gray.700"
            borderRadius="full"
            mt="4"
            maxW="485px"
            placeholder={t('hubs.landing.searchPlaceholder')}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Flex gap={{ base: 4, lg: 6 }} justifyContent="center" flexWrap="wrap" mt={{ base: 6, md: 12, lg: '93px' }}>
            <NextLink href="/lectures" passHref legacyBehavior>
              <HubsNavigationButton>{t('hubsNavigation.conferenceTalks')}</HubsNavigationButton>
            </NextLink>
            <NextLink href="/conferences" passHref legacyBehavior>
              <HubsNavigationButton>{t('hubsNavigation.conferences')}</HubsNavigationButton>
            </NextLink>
            <NextLink href="/speakers" passHref legacyBehavior>
              <HubsNavigationButton>{t('hubsNavigation.speakers')}</HubsNavigationButton>
            </NextLink>
            <NextLink href="/societies" passHref legacyBehavior>
              <HubsNavigationButton>{t('hubsNavigation.societies')}</HubsNavigationButton>
            </NextLink>
          </Flex>
          <FeaturedLectures mt={{ base: 4, md: 18 }} />
        </Flex>
      </CoreHeroBody>
    </CoreHubsHero>
  );
};
